import { convertNullIfEmpty } from "@/utils/null-check";
class ProjectFloorPlanModel {
    static postPayload(e) {
        return {
            projectUnitTypeId: e.projectUnitTypeId || "",
            lotTypeId: e.lotTypeId || "",
            image: e.image || [],
            thumbnail: e.thumbnail || [],
            unitImage: e.unitImage || [],
            name: e.name || "",
            landWidth: e.landWidth ? e.landWidth.toString() : "0",
            landDepth: e.landDepth ? e.landDepth.toString() : "0",
            landArea: e.landArea ? e.landArea.toString() : "0",
            numberOfBedroom: parseInt(e.numberOfBedroom) || 0,
            numberOfBathroom: parseInt(e.numberOfBathroom) || 0,
            extraBedroom: parseInt(e.extraBedroom) || 0,
            extraBathroom: parseInt(e.extraBathroom) || 0,
            salePrice: e.salePrice ? e.salePrice.toString() : "0",
            salePricePerUnit: e.salePricePerUnit
                ? e.salePricePerUnit.toString()
                : "0",
            builtUpWidth: e.builtUpWidth ? e.builtUpWidth.toString() : "0",
            builtUpDepth: e.builtUpDepth ? e.builtUpDepth.toString() : "0",
            builtUpArea: e.builtUpArea ? e.builtUpArea.toString() : "0",
            areaUnitType: e.areaUnitType || "",

            isEdit: e.isEdit || false
        };
    }

    static getToEditResponse(e) {
        return {
            projectUnitTypeId: e.projectUnitType.id || "",
            lotTypeId: e.lotType ? e.lotType.id : "",
            image: e.image || [],
            thumbnail: e.thumbnail || [],
            unitImage: e.unitImage || [],
            name: e.name || "",
            landWidth: convertNullIfEmpty(e.landWidth) || "",
            landDepth: convertNullIfEmpty(e.landDepth) || "",
            landArea: convertNullIfEmpty(e.landArea) || "",
            numberOfBedroom: e.numberOfBedroom || 0,
            numberOfBathroom: e.numberOfBathroom || 0,
            extraBedroom: convertNullIfEmpty(e.extraBedroom) || "",
            extraBathroom: convertNullIfEmpty(e.extraBathroom) || "",
            salePrice: e.salePrice || "0",
            salePricePerUnit: e.salePricePerUnit || "0",
            builtUpWidth: convertNullIfEmpty(e.builtUpWidth) || "",
            builtUpDepth: convertNullIfEmpty(e.builtUpDepth) || "",
            builtUpArea: convertNullIfEmpty(e.builtUpArea) || "",
            areaUnitType: e.areaUnitType || "",

            isEdit: e.isEdit || false
        };
    }
}

export default ProjectFloorPlanModel;
